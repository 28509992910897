import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import checklistImage from '../../img/imagesScreens/18.png'

const PreOperatorioPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <ImageScreen src={checklistImage} />
                    <Titulo texto="Cuidados Pré-operatórios" />
                </div>
                <div className="row">
                    <div className="col content">
                    <Paragrafo>
                        Jejum pré-operatório:<br />
                        Água/chá: até 2 horas antes do horário previsto para cirurgia;<br />
                        Leite de vaca e sólidos: até 8 horas antes do horário previsto para cirurgia;
                    </Paragrafo>

                    <Paragrafo>
                        Seguir as orientações médicas para manutenção ou suspensão dos medicamentos de uso habitual
                    </Paragrafo>

                    <Paragrafo>
                        Não utilizar, no dia da cirurgia, cosméticos (Batom, base, esmalte, pintura facial), acessórios (óculos, lentes de contato, brincos, colares, anéis, relógios, pulseiras etc) e remover peças dentárias móveis como: dentaduras, pivôs e pontes;
                    </Paragrafo>

                    <Paragrafo>
                        Conferir e levar para o hospital todos os exames e laudos pré-operatórios: avaliações médicas, resultado de biópsias,mamografia, ultrassonografia, ressonância magnética, rx de tórax, tomografia, linfocintilografia, exames de sangue e urina, etc.
                    </Paragrafo>

                    <Paragrafo>
                        Não esquecer o termo de consentimento informado, que será anexado com o prontuário durante a internação do procedimento anestésico-cirúrgico ao qual será submetido.
                    </Paragrafo>

                    <Paragrafo>
                        Não depile o local a ser operado antes da cirurgia. A retirada dos pelos será realizada no hospital com tricotomizador elétrico.
                    </Paragrafo>

                    <Paragrafo>
                         Tome banho completo na noite anterior e novamente no dia da cirurgia, o mais próximo possível do horário da operação. Use toalha e roupas limpas.
                    </Paragrafo>

                    <Paragrafo>
                        Comunique ao hospital ou cirurgião, antes da cirurgia, se estiver com alguma infecção (furúnculo, foliculite, sinusite, dor de garganta, infecção urinária ou gripe, por exemplo). Nesses casos, se possível, é melhor postergar a cirurgia para evitar que haja risco de infecção pós-operatória.
                    </Paragrafo>

                    <Paragrafo>
                        Deixe de fumar um mês antes da cirurgia. O fumo pode trazer complicações cardiovasculares, pulmonares e cicatriciais.
                    </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default PreOperatorioPage