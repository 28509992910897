import React from 'react'
import Titulo from '../../components/Titulo'
import FooterButton from '../../components/FooterButton'
import Paragrafo from '../../components/Paragrafo'
import ImageScreen from '../../components/ImageScreen'
import checklistImage from '../../img/imagesScreens/18.png'

const IntercorrenciasPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <ImageScreen src={checklistImage} />
                    <Titulo texto="Intercorrências" />
                </div>
                <div className="row">
                    <div className="col content">
                        <Paragrafo>
                            Sangramentos (Hematomas)
                        </Paragrafo>

                        <Paragrafo>
                            Tromboses (Coágulos sanguíneos)
                        </Paragrafo>

                        <Paragrafo>
                            Inchaços no local da cirurgia
                        </Paragrafo>

                        <Paragrafo>
                            Dor
                        </Paragrafo>

                        <Paragrafo>
                            Acúmulos de líquidos (seroma)
                        </Paragrafo>

                        <Paragrafo>
                            Problemas na cicatrização
                        </Paragrafo>

                        <Paragrafo>
                            Necroses (morte) da pele, gordura e músculos
                        </Paragrafo>

                        <Paragrafo>
                            Despigmentação (perda da cor) da pele
                        </Paragrafo>

                        <Paragrafo>
                            Cicatrizes desfavoráveis
                        </Paragrafo>

                        <Paragrafo>
                            Alterações na sensibilidade da pele
                        </Paragrafo>

                        <Paragrafo>
                            Mudanças no braço do lado operado como dormências, inchaços, fraqueza e limitação de movimento
                        </Paragrafo>

                        <Paragrafo>
                            No tratamento conservador: mamas assimétricas (desiguais)
                        </Paragrafo>

                        <Paragrafo>
                            Na reconstrução com uso de implantes: vazamento, ruptura e formação de tecido fibrótico (contratura capsular)
                        </Paragrafo>

                        <Paragrafo>
                            Infecções
                        </Paragrafo>

                        <Paragrafo>
                            Possibilidade de revisão cirúrgica
                        </Paragrafo>

                        <Paragrafo>
                            Importante: todas as possíveis intercorrências deverão ser debatidas com seu mastologista antes de assinar o termo de consentimento da cirurgia.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default IntercorrenciasPage