import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import Button from '../../components/button'
import ImageScreen from '../../components/ImageScreen'
import FooterButton from '../../components/FooterButton'
import imageCirurgia from '../../img/imagesScreens/1.png'

const CirurgiaConservadoraPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Cirurgia Conservadora" />
                    <ImageScreen src={imageCirurgia} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>

                        <Paragrafo>
                        Técnica cirúrgica onde a mama é preservada retirando apenas o tumor com uma margem de segurança (ausência de células cancerígenas na borda do tecido, sugerindo que todo o tumor foi removido).
                        </Paragrafo>

                        <Paragrafo>
                            Também denominada de setorectomia ou quadrantectomia.
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>

                        <Paragrafo>
                        Esse procedimento é utilizado quando o tumor é único, sem comprometimento de pele ou parede torácica e relação do tamanho da mama e do tumor menor que 20%.
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            É retirado um segmento da mama que contém o tumor e tecido normal adjacente (margem de segurança).
                        </Paragrafo>

                        <Paragrafo>
                            Em tumores não palpáveis, pode ser necessário o agulhamento pré-operatório para a localização do tumor.
                        </Paragrafo>

                        <Paragrafo>
                            Dependendo da localização do tumor podem ser necessárias técnicas de cirurgia plástica para reconstrução.{"\n"}
                        </Paragrafo>

                        <Paragrafo>
                            A retirada do mamilo pode ser necessária dependendo da localização do tumor.
                        </Paragrafo>

                        <Paragrafo>
                            A simetrização da mama contralateral e a reconstrução do mamilo, quando necessário, serão realizadas no mesmo dia ou no segundo tempo cirúrgico.
                        </Paragrafo>

                        <SubTitulo>Particularidades</SubTitulo>

                        <Paragrafo>
                            A radioterapia é obrigatória no pós-operatório para reduzir a taxa de recidiva local.
                        </Paragrafo>

                        <Paragrafo>
                            A sobrevida (período em que o paciente está vivendo com a doença estabilizada) é equivalente quando se compara a cirurgia conservadora e mastectomia (retirada total da mama).
                        </Paragrafo>

                        <Paragrafo>
                            O tipo de cirurgia, conservadora ou mastectomia, não influencia na indicação de tratamento sistêmico (trata o organismo como um todo) como quimioterapia, hormonioterapia e terapia alvo.
                        </Paragrafo>

                        <Button
                            text="Ver reconstrução mamária"
                            to='reconstrucao'
                        />
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default CirurgiaConservadoraPage

// export default class CirurgiaConservadoraScreen extends Component {
//     static navigationOptions = {header: null}
//     render(){
//         return (
//             <View style={styles.container}>
//                 <ImageBackground
//                     source={assets.background}
//                     style={styles.background}
//                 >
//                     <Logo />

//                     <ScrollView contentContainerStyle={{
//                         alignItems: 'center'
//                     }}>
//                         <Tex//                         >Cirurgia Conservadora</Paragrafo>

//                         <View
//                             style={{
//                                 width: '100%',
//                                 height: 200
//                             }}>
//                             <Image style={{
//                                 width: '100%',
//                                 height: '100%',
//                                 resizeMode: "contain"
//                             }} source={assets.cirurgiaConservadora}/>
//                         </View>
//                         <View>

//                             <Tex//                             >O que é?</Paragrafo>
//                             <Paragrafo>
//                             Técnica cirúrgica onde a mama é preservada retirando apenas o tumor com uma margem de segurança (ausência de células cancerígenas na borda do tecido, sugerindo que todo o tumor foi removido).
//                             </Text>

//                             <Text style={styles.contentText}>
//                                 Também denominada de setorectomia ou quadrantectomia.
//                             </Text>

//                             <Tex//                             >Quando utilizar?</Text>

//                             <Text style={styles.contentText}>
//                             Esse procedimento é utilizado quando o tumor é único, sem comprometimento de pele ou parede torácica e relação do tamanho da mama e do tumor menor que 20%.
//                             </Text>

//                             <Tex//                             >Como é realizada?</Text>

//                             <Text
//                                 style={styles.contentText}
//                             >
//                                 É retirado um segmento da mama que contém o tumor e tecido normal adjacente (margem de segurança).




//                             </Text>

//                             <Text
//                                 style={styles.contentText}
//                             >
//                                 Em tumores não palpáveis, pode ser necessário o agulhamento pré-operatório para a localização do tumor.
//                             </Text>

//                             <Text style={styles.contentText}>
//                                 Dependendo da localização do tumor podem ser necessárias técnicas de cirurgia plástica para reconstrução.{"\n"}
//                             </Text>
                            
                            

//                             <Text style={styles.contentText}>
//                                 A retirada do mamilo pode ser necessária dependendo da localização do tumor.
//                             </Text>

//                             <Text style={styles.contentText}>
//                                 A simetrização da mama contralateral e a reconstrução do mamilo, quando necessário, serão realizadas no mesmo dia ou no segundo tempo cirúrgico.
//                             </Text>

//                             <Tex//                             >Particularidades</Text>
//                             <Text style={styles.contentText}>
//                                 A radioterapia é obrigatória no pós-operatório para reduzir a taxa de recidiva local.
//                             </Text>

//                             <Text style={styles.contentText}>
//                                 A sobrevida (período em que o paciente está vivendo com a doença estabilizada) é equivalente quando se compara a cirurgia conservadora e mastectomia (retirada total da mama).
//                             </Text>

//                             <Text style={styles.contentText}>
//                                 O tipo de cirurgia, conservadora ou mastectomia, não influencia na indicação de tratamento sistêmico (trata o organismo como um todo) como quimioterapia, hormonioterapia e terapia alvo.
//                             </Text>

//                             <Button
//                                 text="Ver reconstrução mamária"
//                                 onPress={() => this.props.navigation.navigate('reconstrucao')}
//                             />
//                         </View>
//                     </ScrollView>
//                     <TouchableHighlight
//                         onPress={() => this.props.navigation.pop()}
//                     >
//                         <View
//                             style={{
//                                 height: 50,
//                                 alignItems: 'center',
//                                 justifyContent: 'center',
//                                 backgroundColor: 'white'
//                             }}
//                         >
//                             <Paragrafo>Voltar</Text>
//                         </View>
//                     </TouchableHighlight>
//                 </ImageBackground>
//             </View>
//         )
//     }
// }