import React from 'react'
import mpcas from '../../img/mpcas.png'
import style from "./index.module.css"
import Button from "../../components/button"

const HomePage = () => {
    return (
        <div className="container-fluid">
            <div className={"row"}>
                <div className={style.authorContainer + " col"}>
                    <p>Flavio Dutra Miranda</p>
                    <p>Geraldo Magela Salomé</p>
                    <p>Taylor Brandão Schnaider</p>
                </div>
            </div>
            <div className="row">
                <div className={style.mpcasContainer + " col"}>
                    <img className="img-fluid" src={mpcas} alt="Mestrado Profissional em Ciências Aplicadas à Saúde"/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Button text="Iniciar" to="/initialMenu" />
                </div>
            </div>
        </div>
    )
}

export default HomePage