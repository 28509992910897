import React from 'react'
import Titulo from '../../components/Titulo'
import Button from '../../components/button'
import FooterButton from '../../components/FooterButton'

const InformarCuidadosPosPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Cuidados pós-operatórios" />
                </div>
                <div className="row">
                    <div className="col content">
                        <Button
                            text="Cuidados com a ferida operatória"
                            to={"cuidadosFerida"}
                        />
                        <br />
                        <Button
                            text="Cuidados com o dreno"
                            to={"cuidadosDreno"}
                        />
                        <br />
                        <Button
                            text="Cuidados com o braço"
                            to={"cuidadosBraco"}
                        />
                        <br />
                        <Button
                            text="Cuidados gerais"
                            to={"cuidadosGerais"}
                        />
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default InformarCuidadosPosPage