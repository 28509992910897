import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import FooterButton from '../../components/FooterButton'

const ObjetivoPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Objetivos" />
                </div>
                <div className="row">
                    <div className="col content">
                        <Paragrafo >
                            O aplicativo tem o objetivo de orientar pacientes sobre o tratamento cirúrgico do câncer de mama incluindo os cuidados pré-operatórios, tipos de cirurgias e cuidados pós-operatórios.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default ObjetivoPage

// export default class ObjetivoScreen extends Component {
//     static navigationOptions = {header: null}
//     render(){
//         return (
//             <View style={styles.container}>
//                 <ImageBackground
//                     source={assets.background}
//                     style={styles.background}
//                 >
//                     <Logo />

//                     <ScrollView contentContainerStyle={{
//                         alignItems: 'center'
//                     }}>
                        

//                         <Text
//                             style={styles.titlePage}
//                         >Objetivos</Text>

//                         <Text
//                             style={styles.contentText}
//                         >
//                             O aplicativo tem o objetivo de orientar pacientes sobre o tratamento cirúrgico do câncer de mama incluindo os cuidados pré-operatórios, tipos de cirurgias e cuidados pós-operatórios.
//                         </Text> 
//                     </ScrollView>
//                     <TouchableHighlight
//                         onPress={() => this.props.navigation.pop()}
//                     >
//                         <View
//                             style={{
//                                 height: 50,
//                                 alignItems: 'center',
//                                 justifyContent: 'center',
//                                 backgroundColor: 'white'
//                             }}
//                         >
//                             <Text>Voltar</Text>
//                         </View>
//                     </TouchableHighlight>
//                 </ImageBackground>
//             </View>
//         )
//     }
// }