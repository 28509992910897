import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import Button from '../../components/button'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/2.png'

const ExpansoresDeTecidosPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Mastectomia" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>

                        <Paragrafo>
                            Técnica cirúrgica onde toda a glândula mamária é retirada. 
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>

                        <Paragrafo>
                            Indicada em tumores que comprometem a pele, parede torácica e quando a relação do tamanho da mama e tumor é maior que 20%, não sendo possível a utilização de técnicas de mamoplastia ou retalhos locais.
                        </Paragrafo>

                        <Paragrafo>
                            Também realizada em pacientes com contraindicações para radioterapia, com mutação genética e na presença de dois ou mais tumores em diferentes quadrantes da mama.
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            * Mastectomia total: retirada completa da mama incluindo pele e mamilo.
                        </Paragrafo>

                        <Paragrafo>
                            
                            * Mastectomia poupadora da pele: retirada completa da glândula mamária preservando a pele. Este procedimento é seguido de reconstrução mamária imediata.
                        </Paragrafo>

                        <Paragrafo>
                            * Mastectomia poupadora do mamilo: retirada completa da glândula mamária preservando a pele e o mamilo. Este procedimento é seguido pela reconstrução mamária imediata.
                        </Paragrafo>

                        <SubTitulo>Particularidades</SubTitulo>

                        <Paragrafo>
                            A reconstrução mamária após a mastectomia total pode ser imediata (mesmo dia da retirada da mama) ou tardia (segundo tempo cirúrgico). 
                        </Paragrafo>

                        <Paragrafo>
                            Próteses externas, usadas dentro do sutiã, só devem ser utilizadas quando a região estiver totalmente cicatrizada e sob orientação médica.
                        </Paragrafo>
                        <Button
                            text="Ver reconstrução mamária"
                            to={'reconstrucao'}
                        />              
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default ExpansoresDeTecidosPage