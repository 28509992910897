import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import FooterButton from '../../components/FooterButton'

const ExpansoresDeTecidosPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Referências Bibliográficas" />
                </div>
                <div className="row">
                    <div className="col content">
                        <Paragrafo>
                            Allemani, C, et al. Global surveillance of trends in cancer survival 2000–14 (Concord-3): analysis of individualrecords for 37 513 025 patients diagnosed with one of 18 cancers from 322 population-based registries in 71countries. Lancet.2018; 391(10125):1023-75.
                        </Paragrafo>
                        <Paragrafo>
                            American Society of Clinical Oncology (ASCO). What is cancer surgery? https://www.cancer.net/navigating-cancer-care/how-cancer-treated/surgery/what-cancer-surgery. Access in: 12 jan. 2020.
                        </Paragrafo>
                        <Paragrafo>
                            American Society of Plastic Surgeons. Breast Reconstruction. https://www.plasticsurgery.org/reconstructive-procedures/breast-reconstruction. Access in: 10 jan. 2020.
                        </Paragrafo>
                        <Paragrafo>
                            Boughey JC, et al. Identification and Resection of Clipped Node Decreases the False-negative Rate of Sentinel Lymph Node Surgery in Patients Presenting With Node-positive Breast Cancer (T0-T4, N1-N2) Who Receive Neoadjuvant Chemotherapy: Results From ACOSOG Z1071 (Alliance). Ann Surg. 2016 ;263(4):802-7.
                        </Paragrafo>
                        <Paragrafo>
                            De La Cruz L, et al. Outcomes after oncoplastic breast-conserving surgery in breast cancer patients: A systematic literature review. Annals of Surgical Oncology 2016; 23(10):3247-58.
                        </Paragrafo>
                        <Paragrafo>
                            De Lorenzi F, et al. Oncological results of oncoplastic breast-conserving surgery: Long term follow-up of a large series at a single institution: A matched-cohort analysis. Eur J Surg Oncol. 2016;42(1):71-7.
                        </Paragrafo>
                        <Paragrafo>
                            Donker M, et al. Radiotherapy or surgery of the axilla after a positive sentinel node in breast cancer (EORTC 10981-22023 AMAROS): a randomised, multicentre, open-label, phase 3 non-inferiority trial.Lancet Oncol. 2014 ;15(12):1303-10.
                        </Paragrafo>
                        <Paragrafo>
                            Drury KE, et al. Impact of postoperative antibiotic prophylaxis duration on surgical site infections in autologous breast reconstruction. Ann Plast Surg. 2016;76(2):174-79.
                        </Paragrafo>
                        <Paragrafo>
                            Giuliano AE, et al. Effect of Axillary Dissection vs No Axillary Dissection on 10-Year Overall Survival Among Women With Invasive Breast Cancer and Sentinel Node Metastasis: The ACOSOG Z0011 (Alliance) Randomized Clinical Trial.JAMA, 2017; 318(10):918-26.
                        </Paragrafo>
                        <Paragrafo>
                            Goldman RE, et al. Brazilian Breast Cancer Care Network: the perspective of health managers. Rev Bras Enferm. 2019;72(Suppl 1):274-81.
                        </Paragrafo>
                        <Paragrafo>
                            Henry NL, et al. Chapter 88: Cancer of the Breast. In: Niederhuber JE, Armitage JO, Doroshow JH, Kastan MB, Tepper JE, eds. Abeloff’s Clinical Oncology. 6th ed. Philadelphia, Pa: Elsevier; 2020.
                        </Paragrafo>
                        <Paragrafo>
                            Instituto Nacional de Câncer José Alencar Gomes da Silva. Estimativa 2020: Incidência de Câncer no Brasil. Rio de Janeiro: INCA, 2019. Acesso em 06 fev. 2020.
                        </Paragrafo>
                        <Paragrafo>
                            Jagsi R, et al. Chapter 79: Malignant Tumors of the Breast. In: DeVita VT, Lawrence TS, Lawrence TS, Rosenberg SA, eds. DeVita, Hellman, and Rosenberg’s Cancer: Principles and Practice of Oncology. 11th ed. Philadelphia, Pa: Lippincott Williams &amp; Wilkins; 2019.
                        </Paragrafo>
                        <Paragrafo>
                            Losken A, et al. A Meta-Analysis Comparing Breast Conservation Therapy Alone to the Oncoplastic Technique. Ann Plast Surg. 2014;72(2):145-49.
                        </Paragrafo>
                        <Paragrafo>
                            Moran MS, et al. Consensus guideline on margins for breast-conserving surgery with whole breast irradiation in stages I and II invasive breast cancer. J Clin Oncol 2014;32(14):1507-15.
                        </Paragrafo>
                        <Paragrafo>
                            National Cancer Institute (NCI). Surgery to treat cancer. https://www.cancer.gov/about-cancer/treatment/types/surgery. Access in: 11 jan. 2020.
                        </Paragrafo>
                        <Paragrafo>
                            National Comprehensive Cancer Network (NCCN). Practice Guidelines in Oncology: Breast Cancer. Version 1.2020,https://www.nccn.org/professionals/physician_gls/pdf/breast.pdf. Access in: 12 jan. 2020.
                        </Paragrafo>
                        <Paragrafo>
                            Pollhammer MS, et al. Recent advances in microvascular autologous breast reconstruction after ablative tumorsurgery. World Journal of Clinical Oncology. 2016;7(1):114-21.
                        </Paragrafo>
                        <Paragrafo>
                            Rocco N, et al. Different types of implants for reconstructive breast surgery. Cochrane Database of Systematic Reviews. 2016, Issue 5. Art. No.: CD010895.
                        </Paragrafo>
                        <Paragrafo>
                            Shaitelman SF, et al. Recent progress in the treatment and prevention of cancer-related lymphedema.CA Cancer JClin 2015 ;65(1):55-81.
                        </Paragrafo>
                        <Paragrafo>
                            Sisco M e Yao KA. Nipple-sparing mastectomy: A contemporary perspective. J Surg Oncol. 2016; 113:883–90.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default ExpansoresDeTecidosPage