import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/5.png'

const ProtesesMatrixPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Próteses de silicone" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>
                        <Paragrafo>
                            Tipo de reconstrução mamária onde é utilizado próteses de silicone para restaurar a aparência natural da mama.
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>

                        <Paragrafo>
                            A prótese é utilizada para substituir o tecido glandular extirpado (parte ou toda a mama) ou para aumentar o volume da mama.
                        </Paragrafo>

                        <Paragrafo>
                            Realizada após tratamento conservador ou mastectomia. Pode ser realizada na reconstrução imediata (mesmo dia da retirada da mama) ou tardia (segundo tempo cirúrgico).
                        </Paragrafo>

                        <Paragrafo>
                            A retirada de pele deve restringir-se ao mínimo necessário para haver pele suficiente para cobertura da prótese. Sendo assim, a melhor indicação são os casos em que é retirada apenas a glândula, mantendo-se a maior quantidade de pele possível.
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            Nos casos de retirada parcial da glândula mamária, introduz-se a prótese pela incisão cirúrgica. Em mamas muito caídas, pode-se ressecar o excesso de pele, através da qual coloca-se a prótese.
                        </Paragrafo>

                        <Paragrafo>
                            Nos casos de retirada total da glândula mamária, cria-se um espaço entre os músculos peitorais para colocação da prótese.
                        </Paragrafo>

                        <SubTitulo>Particularidades</SubTitulo>

                        <Paragrafo>
                            Pode ser necessário o uso de matriz dérmica acelular que é um biomaterial utilizado nas reconstruções com próteses que funciona como uma extensão do músculo peitoral para facilitar a cobertura dos implantes.
                        </Paragrafo>

                        <Paragrafo>
                            A simetrização da mama contralateral será realizada no mesmo dia ou no segundo tempo cirúrgico.
                        </Paragrafo>

                        <Paragrafo>
                            A radioterapia pode comprometer a reconstrução com prótese aumentando de chances de contratura da cápsula do implante e extrusão (exteriorização) do mesmo.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default ProtesesMatrixPage