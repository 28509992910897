import React from "react"
import style from "./index.module.css"
import { Link } from "react-router-dom"

const Button = ({text, to}) => {
    return (
        <div className="text-center">
            <Link className={style.button} to={to}>{text}</Link>
        </div>
    )
}

export default Button