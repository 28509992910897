import React from "react"
import style from "./index.module.css"
import { useHistory } from "react-router-dom"

const FooterButton = ({text, to}) => {
    const history = useHistory()
    return (
        <div className={style.button}>
            <button onClick={history.goBack}>{text}</button>
        </div>
    )
}

export default FooterButton