import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import imageMamoplastia from '../../img/imagesScreens/4.png'

const RetalhosLocorregionaisPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Retalhos Locais" />
                    <ImageScreen src={imageMamoplastia} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>
                        <Paragrafo>
                            Tipo de tratamento conservador da mama que tem o objetivo de reposição do volume mamário com retalhos não mamários adjacentes.
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>

                        <Paragrafo>
                            O procedimento é indicado quando técnicas de mamoplastia redutora não podem ser utilizadas principalmente em mamas sem ptose (queda) e casos que grande quantidade de pele deve ser retirada por questões oncológicas.
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            A pele e gordura da região axilar ou abaixo da mama são transpostas para a região mamária.
                        </Paragrafo>

                        <SubTitulo>Particularidades?</SubTitulo>

                        <Paragrafo>
                            A simetrização da mama contralateral será realizada no mesmo dia ou no segundo tempo cirúrgico.
                        </Paragrafo>

                        <Paragrafo>
                            Em tumores muito próximos à aréola há necessidade de reconstruir o mamilo que será realizada no mesmo dia ou no segundo tempo cirúrgico.
                        </Paragrafo>

                        <Paragrafo>
                            Pode ser necessária a utilização de próteses de silicone
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default RetalhosLocorregionaisPage