import React from 'react'
import Button from '../../components/button'
import FooterButton from '../../components/FooterButton'
import styles from './style.module.css'

const InitialMenuPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className={styles.containerMenu + " col"}>
                        <Button
                            text="Sobre o aplicativo"
                            to="objetivo"
                        />

                        <Button
                            text="Cuidados pré-operatórios"
                            to="menupreoperatorio"
                        />

                        <Button
                            text="Informar cirurgia mamária proposta"
                            to="informarCirurgia"
                        />

                        <Button
                            text="Informar cirurgia axilar proposta"
                            to="informarCirurgiaAxilar"
                        />

                        <Button
                            text="Cuidados pós-operatórios"
                            to="posoperatorio"
                        />

                        <Button
                            text="Referências Bibliográficas"
                            to="referencias"
                        />
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default InitialMenuPage