import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/11.png'

const ExpansoresDeTecidosPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Linfadenectomia Axilar" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>

                        <Paragrafo>
                            O câncer de mama normalmente se dissemina inicialmente para os linfonodos da axila.
                        </Paragrafo>

                        <Paragrafo>
                            Os linfonodos são órgãos do sistema linfático que atuam na defesa do nosso organismo contra infecções.
                        </Paragrafo>

                        <Paragrafo>
                            Este procedimento tem o objetivo avaliar se o câncer de mama disseminou para os linfonodos axilares retirando o maior número possível de linfonodos axilares (geralmente entre 6 e 40).
                        </Paragrafo>

                        <SubTitulo>Quando Utilizar?</SubTitulo>

                        <Paragrafo>
                            Indicada tumores invasivos localmente avançados, em linfonodos clinicamente positivos para câncer e nos casos de três ou mais linfonodos sentinelas com metástases.
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            A dissecção dos linfonodos axilares geralmente é realizada no momento da mastectomia ou da cirurgia conservadora da mama, mas pode ser realizada em uma segunda cirurgia
                        </Paragrafo>

                        <SubTitulo>Particularidades</SubTitulo>

                        <Paragrafo>
                            A avaliação dos linfonodos da cadeia axilar no câncer de mama é muito importante, pois interfere diretamente na tomada de decisões terapêuticas, sendo importante fator prognóstico.
                        </Paragrafo>

                        <Paragrafo>
                            A técnica também traz um risco de dano na drenagem linfática da axila e pode causar o linfedema (inchaço no braço), dor, limitação de movimento, dormência e fraqueza do braço, sendo importante prevenir essas complicações com a realização de fisioterapia pré e pós-operatória.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default ExpansoresDeTecidosPage