import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/18.png'

const ExpansoresDeTecidosPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Cuidados Gerais" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <Paragrafo>
                            No pós-operatório, realizar caminhadas em casa, subir alguns degraus, descansar em poltronas e evitar ficar muito tempo acamada.
                        </Paragrafo>

                        <Paragrafo>
                            Tome em média 2 litros de água por dia.
                        </Paragrafo>

                        <Paragrafo>
                            Tenha alimentação saudável consumindo verduras, frutas, legumes, gelatina, ovos, manteiga, leite, iogurte, castanha do pará, carne magras de gado, frango ou peixes frescos, resfriados ou congelados.
                        </Paragrafo>

                        <Paragrafo>
                            Evitar refrigerantes, balas, chocolates, sorvetes, biscoitos recheados ou não, leite achocolatado, compotas com açúcar, massas, arroz, carnes gordurosas, salsicha, linguiça, bacon, salgadinhos, pipoca de micro- ondas, bolos, tortas industrializadas, bolachas recheadas, batata frita, sal em excesso, sucos industrializados em pó.
                        </Paragrafo>

                        <Paragrafo>
                            Evitar bebidas alcoólicas.
                        </Paragrafo>

                        <Paragrafo>
                            Tome medicamentos indicados pelo mastologista corretamente.
                        </Paragrafo>

                        <Paragrafo>
                            O banho pode ser no chuveiro.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default ExpansoresDeTecidosPage