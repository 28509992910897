import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/7.png'
import imageRetoAbdominal from '../../img/imagesScreens/8.png'

const RetalhoMiocutaneoPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Grande Dorsal" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>
                        <Paragrafo>
                            Tipo reconstrução mamária que consiste em trazer pele, gordura e parte do músculo grande dorsal das costas para reconstruir a mama.
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>

                        <Paragrafo>
                            Tipo reconstrução realizada após mastectomia e também após tratamento conservador.
                        </Paragrafo>

                        <Paragrafo>
                            Retalho relativamente seguro podendo ser indicado em paciente obesa, tabagista, diabética e pacientes submetidas á radioterapia.
                        </Paragrafo>

                        <Paragrafo>
                            Pode substituir a prótese em cirurgias conservadoras.
                        </Paragrafo>

                        <Paragrafo>
                            Pode ser realizada na reconstrução imediata (mesmo dia da retirada da mama) ou tardia (segundo tempo cirúrgico).
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            Realizado um corte nas costas (dorso) para liberar o músculo grande dorsal, associado ou não a uma ilha de pele. Por baixo da pele da região axilar, realiza-se a transposição do retalho para a região peitoral
                        </Paragrafo>

                        <Paragrafo>
                            As cicatrizes resultantes situam-se no dorso e na mama reconstruída.
                        </Paragrafo>

                        <Paragrafo>
                            Em caso de mastectomia normalmente é necessário o uso de prótese para repor o volume da mama.
                        </Paragrafo>

                        <SubTitulo>Particularidades</SubTitulo>

                        <Paragrafo>
                            A simetrização da mama contralateral e a reconstrução do mamilo, quando necessário, serão realizadas no mesmo dia ou no segundo tempo cirúrgico.
                        </Paragrafo>

                        <Paragrafo>
                            Pode ser necessária a troca da prótese nos casos de rompimento ou revisão cirúrgica após alguns anos.
                        </Paragrafo>




                        
                    </div>
                </div>
                <div className="row">
                    <Titulo texto="Retalho Miocutâneo" />
                    <ImageScreen src={imageRetoAbdominal} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>Reto abdominal (TRAM)</SubTitulo>

                        <SubTitulo>O que é?</SubTitulo>
                            
                        <Paragrafo>
                            Tipo reconstrução mamária que consiste  em trazer pele, gordura e  músculo retoabdominal da porção inferior do abdômen para reconstruir a mama.
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>

                        <Paragrafo>
                            Tipo reconstrução utilizada após mastectomia com perda significativa de pele e musculatura 
                        </Paragrafo>

                        <Paragrafo>
                            O retalho transferido costuma ser suficiente para reconstruir a mama sem necessidade de prótese
                        </Paragrafo>

                        <Paragrafo>
                            Boa opção para pacientes submetidas á radioterapia 
                        </Paragrafo>

                        <Paragrafo>
                            Pode ser realizada na reconstrução imediata (mesmo dia da retirada da mama) ou tardia (segundo tempo cirúrgico).
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            Realizado um corte no abdome para retirar pele semelhante à dermolipectomia abdominal. Esta pele permanece fixada aos vasos sanguíneos do músculo reto abdominal, pelos quais chega o sangue para sua nutrição. Por baixo da pele da região abdominal superior, realiza-se a transposição do retalho para a região torácica. 
                        </Paragrafo>

                        <Paragrafo>
                            As cicatrizes resultantes situam-se no abdome e na mama reconstruída.
                        </Paragrafo>

                        <Paragrafo>
                            O retalho transferido costuma ser suficiente para reconstruir a mama sem necessidade de prótese
                        </Paragrafo>

                        <SubTitulo>Particularidades?</SubTitulo>

                        <Paragrafo>
                            Aplica-se uma tela de prolene para substituir o músculo deslocado e reduzir a possibilidade de hérnias e abaulamentos, que ocorrem em 10 a 20% dos casos
                        </Paragrafo>

                        <Paragrafo>
                            Índice de complicações significativo e por este motivo, as contra-indicações relativas devem ser valorizadas: obesidade, tabagismo, idade avançada, diabetes, abdome em avental etc.
                        </Paragrafo>

                        <Paragrafo>
                            Tempo de internação mais prolongado que os outros métodos: 3 ou + dias
                        </Paragrafo>

                        <Paragrafo>
                            Sangramento mais significativo que as outras técnicas: maior chance de transfusão sanguínea
                        </Paragrafo>

                        <Paragrafo>
                            A simetrização da mama contralateral e a reconstrução do mamilo, quando necessário, serão realizadas no mesmo dia ou no segundo tempo cirúrgico.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default RetalhoMiocutaneoPage
