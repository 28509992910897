import React from 'react'
import Button from '../../components/button'
import Titulo from '../../components/Titulo'
import FooterButton from '../../components/FooterButton'

const MenuPreOperatorioPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Cuidados Pré-operatórios" />
                </div>
                <div className="row">
                    <div className="col content">
                        <Button text="Cuidos Pré-operatórios" to="preoperatorio"/>
                        <br />
                        <Button text="Intercorrências" to="intercorrencias"/>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default MenuPreOperatorioPage
