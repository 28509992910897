import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/9.png'

const RetalhosMicrocirurgicoPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Retalho Microcirúrgico" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>
                        
                        <Paragrafo>
                            Tipo reconstrução mamária que consiste em trazer pele, gordura e músculo (reto abdominal, glúteos etc.) para reconstruir a mama.
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>

                        <Paragrafo>
                            Tipo reconstrução utilizada após mastectomia com perda significativa de pele e musculatura.
                        </Paragrafo>

                        <Paragrafo>
                            O retalho transferido costuma ser suficiente para reconstruir a mama sem necessidade de prótese.
                        </Paragrafo>

                        <Paragrafo>
                            Boa opção para pacientes submetidas à radioterapia.
                        </Paragrafo>

                        <Paragrafo>
                            Pode ser realizada na reconstrução imediata (mesmo dia da retirada da mama) ou tardia (segundo tempo cirúrgico).
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            O retalho é completamente destacado da região de origem e os seus vasos são suturados a vasos da axila ou da região esternal, de modo a restabelecer a circulação.
                        </Paragrafo>

                        <Paragrafo>
                            A anastomose (comunicação) dos vasos é realizada com apoio de microscópio.
                        </Paragrafo>

                        <Paragrafo>
                            As cicatrizes resultantes situam-se na área doadora e na mama reconstruída.
                        </Paragrafo>

                        <Paragrafo>
                            O retalho transferido costuma ser suficiente para reconstruir a mama sem necessidade de prótese
                        </Paragrafo>

                        <SubTitulo>Particularidades</SubTitulo>

                        <Paragrafo>
                            Aplica-se uma tela de prolene para substituir o músculo deslocado e reduzir a possibilidade de hérnias e abaulamentos, que ocorrem em 10 a 20% dos casos.
                        </Paragrafo>

                        <Paragrafo>
                            Índice de complicações significativo e por este motivo, as contraindicações relativas devem ser valorizadas: obesidade, tabagismo, idade avançada, diabetes, abdome em avental etc.
                        </Paragrafo>

                        <Paragrafo>
                            Tempo de internação mais prolongado que os outros métodos: 3 ou + dias.
                        </Paragrafo>

                        <Paragrafo>
                            Sangramento mais significativo que as outras técnicas: maior chance de transfusão sanguínea.
                        </Paragrafo>

                        <Paragrafo>
                            A simetrização da mama contralateral e a reconstrução do mamilo, quando necessário, serão realizadas no mesmo dia ou no segundo tempo cirúrgico.                            
                        </Paragrafo>

                        <Paragrafo>
                            Possibilidade significativa de revisão cirúrgica nas primeiras 24 horas.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default RetalhosMicrocirurgicoPage