import React from 'react'
import styles from './styles.module.css'
import Button from '../../components/button'
import FooterButton from '../../components/FooterButton'
import Titulo from '../../components/Titulo'

const InformarSobrePage = () => {
    return (
        <>    
            <div className="container-fluid">
                <div className={"row"}>
                    <Titulo texto="Sobre o aplicativo" />
                </div>
                <div className="row">
                    <div className={styles.buttonContainer + " col"}>
                        <Button text="Introdução" to="/introducao" />
                        <br />
                        <Button text="Objetivos" to="/objetivos" />
                    </div>
                </div>
                
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default InformarSobrePage