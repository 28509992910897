import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/6.png'

const ExpansoresDeTecidosPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Expansores de Tecidos" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>
                        <Paragrafo>
                            Tipo de reconstrução mamária onde é utilizado expansores de tecidos que podem ser temporários ou definitivos para restaurar a aparência natural da mama.
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>

                        <Paragrafo>
                            Utilizada quando a pele não é suficiente para cobrir a prótese de silicone.
                        </Paragrafo>

                        <Paragrafo>
                            Geralmente é realizada na reconstrução imediata (mesmo dia da retirada da mama).
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            A técnica normalmente é realizada em dois tempos:                   
                        </Paragrafo>

                        <Paragrafo>
                            • primeiro tempo: utiliza uma prótese expansora inicialmente vazia e aos poucos através de injeção de soro fisiológico expande a pele ate atingir a capacidade total de expansão.                   
                        </Paragrafo>

                        <Paragrafo>
                            • segundo tempo: a prótese expansora é substituída por uma de silicone, que proporciona uma textura similar à da mama original. neste mesmo tempo, se necessário, pode ser reconstruído o mamilo e a simetrização da mama contralateral.                    
                        </Paragrafo>

                        <SubTitulo>Particularidades</SubTitulo>

                        <Paragrafo>
                            No caso de expansor temporário recomenda-se a sua substituição por prótese de silicone em até 1 ano após sua inclusão.                  
                        </Paragrafo>

                        <Paragrafo>
                            O número de sessões de expansão dependerá do tamanho da mama a ser reconstruída, do limiar de dor da paciente e da qualidade da pele (elasticidade).                    
                        </Paragrafo>

                        <Paragrafo>
                            O enchimento é feito através de uma válvula inclusa na parede do expansor ou ligada por um cateter (válvula remota). Esta válvula permite a introdução de agulhas para aplicação da solução fisiológica e o não extravasamento do líquido após a retirada da agulha.                    
                        </Paragrafo>

                        <Paragrafo>
                            No caso de expansor definitivo não é necessário a troca.                    
                        </Paragrafo>

                        <Paragrafo>
                            A chance de extrusão do expansor e, conseqüente, perda da cirurgia é significativa em pacientes submetidos à radioterapia antes ou após a reconstrução.                     
                        </Paragrafo>

                        <Paragrafo>
                            A simetrização da mama contralateral será realizada no mesmo dia ou no segundo tempo cirúrgico.                 
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default ExpansoresDeTecidosPage