import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import imageMamoplastia from '../../img/imagesScreens/3.png'

const RetalhosGlandularesPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="MAMOPLASTIA" />
                    <ImageScreen src={imageMamoplastia} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>

                        <Paragrafo>
                            Tipo de tratamento conservador da mama onde são utilizadas técnicas de cirurgia plástica para beneficiar o tratamento oncológico (oncoplástica).
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>
                        
                        <Paragrafo>
                            Indicada quando as ressecções são superiores a 20% da mama com a finalidade de prevenir futura deformidade.
                        </Paragrafo>

                        <Paragrafo>
                            Geralmente utilizado em mamas médias e grandes onde são utilizadas técnicas de mamoplastia redutora.
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            O procedimento utiliza o tecido glandular adjacente à área acometida pelo tumor.
                        </Paragrafo>

                        <Paragrafo>
                            Várias técnicas são utilizadas dependendo da localização do tumor.
                        </Paragrafo>

                        <SubTitulo>Particularidades</SubTitulo>

                        <Paragrafo>
                            A simetrização da mama contralateral será realizado no mesmo dia ou no segundo tempo cirúrgico.
                        </Paragrafo>

                        <Paragrafo>
                            Em alguns casos de tumores muito próximos à aréola, há necessidade de reconstruir o mamilo que será realizada no mesmo dia ou no segundo tempo cirúrgico.
                        </Paragrafo>

                        <Paragrafo>
                            Pode ser necessária a utilização de próteses de silicone.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default RetalhosGlandularesPage