import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/18.png'

const ExpansoresDeTecidosPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="ECuidados com a ferida" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <Paragrafo>
                            Manter o curativo nas primeiras 24 horas após a cirurgia
                        </Paragrafo>

                        <Paragrafo>
                            Não há evidências de prejuízo à cicatrização e de aumento de infecção em feridas descobertas após 24 horas da cirurgia.
                        </Paragrafo>

                        <Paragrafo>
                            Lavar bem as mãos antes de iniciar a limpeza da ferida operatória.
                        </Paragrafo>

                        <Paragrafo>
                            Na incisão realizar apenas limpeza com água e sabão e secagem com gaze estéril.
                        </Paragrafo>

                        <Paragrafo>
                            Existem alguns curativos (filmes plásticos e micropore estéril) que são realizados no final da cirurgia e podem ser mantido ate o retorno médico. 
                        </Paragrafo>

                        <Paragrafo>
                            É normal ocorrer saída de secreção serosa ou sanguinolenta pela incisão. Neste caso, a limpeza deve ser feita com soro fisiológico estéril, cobertura da incisão com gaze estéril e fixação do curativo com o mínimo de fita adesiva. O procedimento pode ser repetido quantas vezes for necessário, até interrupção da drenagem.
                        </Paragrafo>

                        <Paragrafo>
                            Procurar a equipe de saúde:
                        </Paragrafo>

                        <Paragrafo>
                            • Em caso de febre (temperatura oral acima de 38,0ºc).
                        </Paragrafo>
                        <Paragrafo>
                            • Sangramento pela ferida operatória.
                        </Paragrafo>
                        <Paragrafo>
                            • Hematoma (acúmulo de sangue) progressivo no local da cirurgia.
                        </Paragrafo>
                        <Paragrafo>
                            • Vermelhidão, inchaço, secreção purulenta ou com cheiro ruim na ferida operatória.
                        </Paragrafo>
                        <Paragrafo>
                            • Se os pontos da cirurgia romper.
                        </Paragrafo>
                        <Paragrafo>
                            • Se o dreno soltar da pele ou perder o vácuo.
                        </Paragrafo>
                        <Paragrafo>
                            Os pontos serão retirados por volta de 14 dias após a cirurgia.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default ExpansoresDeTecidosPage