import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/18.png'
import abrindoValvula from '../../img/imagesScreens/abrindoValvula.jpg'
import fechandoColetor from '../../img/imagesScreens/fechandoColetor.jpg'
import esvaziandoDreno from '../../img/imagesScreens/esvaziandoDreno.jpg'
import volumeDreno from '../../img/imagesScreens/volumeDreno.jpg'
import abrindoColetor from '../../img/imagesScreens/abrindoColetor.jpg'
import fechandoDreno from '../../img/imagesScreens/fechandoDreno.jpg'
import lavarAsMaos from '../../img/imagesScreens/lavarAsMaos.jpg'
import anotacoes from '../../img/imagesScreens/anotacoes.jpg'

const CuidadosDrenoPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Cuidados com o dreno" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <Paragrafo>
                            O dreno de sucção tem por finalidade escoar o liquido que se forma no local da cirurgia.
                        </Paragrafo>

                        <Paragrafo>
                            O dreno fica localizado logo abaixo da pele e fixado com pontos cirúrgicos,
                        </Paragrafo>

                        <Paragrafo>
                            Inicialmente o liquido drenado é sanguinolento e com o passar dos dias vai clareando.
                        </Paragrafo>

                        <Paragrafo>
                            O volume drenado deve ser mensurado diariamente e quando se estabiliza em 50 ml ou menos, o dreno pode ser retirado, o que acontece por volta de 7 a 14 dias. 
                        </Paragrafo>

                        <Paragrafo>
                            Para facilitar a drenagem, posicionar o dreno abaixo da sua inserção.
                        </Paragrafo>

                        <Paragrafo>
                            A manipulação do dreno pode ser realizada pela paciente ou acompanhante (usar luvas de procedimento)
                        </Paragrafo>

                        <Paragrafo>
                            Procurar a equipe de saúde se o dreno soltar da pele ou perder o vácuo.
                        </Paragrafo>

                        <Paragrafo>
                            Diariamente, pela manhã e a noite, o dreno deve ser esvaziado seguindo as seguintes etapas:
                        </Paragrafo>

                        <Paragrafo>
                            * Lave bem as mãos
                        </Paragrafo>

                        <ImageScreen src={lavarAsMaos} />

                        <Paragrafo>
                            * Feche o dreno na mangueira de aspiração
                        </Paragrafo>

                        <ImageScreen src={fechandoDreno} />

                        <Paragrafo>
                            * Abra a saída do líquido e deixe o ar entrar naturalmente para dentro do recipiente
                        </Paragrafo>

                        <ImageScreen src={abrindoColetor} />

                        <Paragrafo>
                            * Observe o volume drenado com o coletor aberto utilizando as marcações de volume no próprio coletor.
                        </Paragrafo>

                        <ImageScreen src={volumeDreno} />
                        
                        <Paragrafo>
                            * Despreze o volume e não lave o interior do coletor
                        </Paragrafo>

                        <ImageScreen src={esvaziandoDreno} />
                        
                        

                        <Paragrafo>
                            * Anote, aproximadamente, o volume drenado e leve no retorno todas as medidas.
                        </Paragrafo>

                        <ImageScreen src={anotacoes} />


                        <Paragrafo>
                            * Comprima o recipiente para criar um novo vácuo e feche a saído do líquido com o coletor totalmente comprimido
                        </Paragrafo>

                        <ImageScreen src={fechandoColetor} />

                        <Paragrafo>
                            * Abra o fecho na mangueira de aspiração
                        </Paragrafo>

                        <ImageScreen src={abrindoValvula} />
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default CuidadosDrenoPage