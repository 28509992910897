import React from 'react'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"

import HomePage from "../../pages/HomePage"
import InitialMenuPage from "../../pages/InitialMenuPage"
import InformarSobrePage from "../../pages/InformarSobrePage"
import ReferenciasPage from "../../pages/ReferenciasPage"
import PreOperatorioPage from "../../pages/PreOperatorioPage"
import InformarCirurgiaPage from "../../pages/InformarCirurgiaPage"
import InformarCirurgiaAxilarPage from "../../pages/InformarCirurgiaAxilarPage"
import CirurgiaConservadoraPage from "../../pages/CirurgiaConservadoraPage"
import MastectomiaPage from "../../pages/MastectomiaPage"
import BiopsiaLinfonodoSentinelaPage from "../../pages/BiopsiaLinfonodoSentinelaPage"
import LinfadenectomiaPage from "../../pages/LinfadenectomiaPage"
import InformarReconstrucaoPage from "../../pages/InformarReconstrucaoPage"
import RetalhosGlandularesPage from "../../pages/RetalhosGlandularesPage"
import RetalhosLocorregionaisPage from "../../pages/RetalhosLocorregionaisPage"
import ProtesesMatrixPage from "../../pages/ProtesesMatrixPage"
import ExpansoresDeTecidosPage from "../../pages/ExpansoresDeTecidosPage"
import RetalhoMiocutaneoPage from "../../pages/RetalhoMiocutaneoPage"
import CuidadosFeridaPage from "../../pages/CuidadosFeridaPage"
import CuidadosDrenoPage from "../../pages/CuidadosDrenoPage"
import CuidadosBracoPage from "../../pages/CuidadosBracoPage"
import RetalhosMicrocirurgicoPage from "../../pages/RetalhosMicrocirurgicoPage"
import InformarCuidadosPosPage from "../../pages/InformarCuidadosPosPage"
import IntercorrenciasPage from "../../pages/IntercorrenciasPage"
import CuidadosGeraisPage from "../../pages/CuidadosGeraisPage"
import MenuPreOperatorioPage from "../../pages/MenuPreOperatorioPage"
import IntroducaoPage from "../../pages/IntroducaoPage"
import ObjetivoPage from "../../pages/ObjetivoPage"

import logo from "../../img/logo@2x.png"
import style from './index.module.css'

function App() {
  return (
    <>
        <header className={style.header + " container-fluid"}>
            <div className="row">
                <div className="col">
                    <img className={style.img + " img-fluid"} src={logo} alt="Oncomastocirurgia APP" />
                </div>
            </div>
        </header>
        <Router>
            <Switch>
                <Route path="/" exact>
                    <HomePage />
                </Route>
                <Route path="/initialMenu" exact>
                    <InitialMenuPage />
                </Route>
                <Route path="/objetivo" exact>
                    <InformarSobrePage />
                </Route>
                <Route path="/introducao" exact>
                    <IntroducaoPage />
                </Route>
                <Route path="/preoperatorio" exact>
                    <PreOperatorioPage />
                </Route>
                <Route path="/informarCirurgia" exact>
                    <InformarCirurgiaPage />
                </Route>
                <Route path="/cirurgiaConservadora" exact>
                    <CirurgiaConservadoraPage />
                </Route>
                <Route path="/reconstrucao" exact>
                    <InformarReconstrucaoPage />
                </Route>
                <Route path="/retalhosGlandulares" exact>
                    <RetalhosGlandularesPage />
                </Route>
                <Route path="/retalhosNaoMamarios" exact>
                    <RetalhosLocorregionaisPage />
                </Route>
                <Route path="/protesesMatrixDermica" exact>
                    <ProtesesMatrixPage />
                </Route>
                <Route path="/expansoresDeTecidos" exact>
                    <ExpansoresDeTecidosPage />
                </Route>
                <Route path="/retalhoMiocutaneo" exact>
                    <RetalhoMiocutaneoPage />
                </Route>
                <Route path="/retalhoMicrocirurgico" exact>
                    <RetalhosMicrocirurgicoPage />
                </Route>
                <Route path="/mastectomiaTotal" exact>
                    <MastectomiaPage />
                </Route>
                <Route path="/informarCirurgiaAxilar" exact>
                    <InformarCirurgiaAxilarPage />
                </Route>
                <Route path="/biopsiaLinfonodo" exact>
                    <BiopsiaLinfonodoSentinelaPage />
                </Route>
                <Route path="/linfadenectomia" exact>
                    <LinfadenectomiaPage />
                </Route>
                <Route path="/posoperatorio" exact>
                    <InformarCuidadosPosPage />
                </Route>
                <Route path="/cuidadosFerida" exact>
                    <CuidadosFeridaPage />
                </Route>
                <Route path="/cuidadosDreno" exact>
                    <CuidadosDrenoPage />
                </Route>
                <Route path="/cuidadosBraco" exact>
                    <CuidadosBracoPage />
                </Route>
                <Route path="/cuidadosGerais" exact>
                    <CuidadosGeraisPage />
                </Route>
                <Route path="/referencias" exact>
                    <ReferenciasPage />
                </Route>
                <Route path="/intercorrencias" exact>
                    <IntercorrenciasPage />
                </Route>
                <Route path="/menupreoperatorio" exact>
                    <MenuPreOperatorioPage />
                </Route>
                <Route path="/objetivos" exact>
                    <ObjetivoPage />
                </Route> 
            </Switch>
        </Router>
    </> 
  )
}

export default App
