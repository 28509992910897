import React from 'react'
import Titulo from '../../components/Titulo'
import Button from '../../components/button'
import FooterButton from '../../components/FooterButton'

const ExpansoresDeTecidosPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Informar Cirurgia Axilar Proposta" />
                </div>
                <div className="row">
                    <div className="col content">
                        <Button
                            text="Biopsia de linfonodo sentinela"
                            to={'biopsiaLinfonodo'}
                        />
                        <br />
                        <Button
                            text="Linfadenectomia axilar"
                            to={"linfadenectomia"}
                        />
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default ExpansoresDeTecidosPage