import React from 'react'
import Titulo from '../../components/Titulo'
import Button from '../../components/button'
import FooterButton from '../../components/FooterButton'

const InformarReconstrucaoPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Informar Reconstrução Mamária" />
                </div>
                <div className="row">
                    <div className="col content">
                        <Button
                            text="Mamoplastia"
                            to={'retalhosGlandulares'}
                        />
                        <br />
                        <Button
                            text="Retalhos locais"
                            to={'retalhosNaoMamarios'}
                        />
                        <br />
                        <Button
                            text="Próteses de silicone"
                            to={'protesesMatrixDermica'}
                        />
                        <br />
                        <Button
                            text="Expansores de tecidos"
                            to={'expansoresDeTecidos'}
                        />
                        <br />
                        <Button
                            text="Retalho miocutâneo"
                            to={'retalhoMiocutaneo'}
                        />
                        <br />
                        <Button
                            text="Retalho microcirúrgico"
                            to={'retalhoMicrocirurgico'}
                        />
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default InformarReconstrucaoPage