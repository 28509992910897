import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/18.png'

import exercicioSentada from '../../img/imagesScreens/13.png'
import paredeTOracica from '../../img/imagesScreens/14.png'
import exercicioOmbro from '../../img/imagesScreens/15.png'
import exercicioCotovelo from '../../img/imagesScreens/16.png'
import exercicioBastao from '../../img/imagesScreens/17.png'

const ExpansoresDeTecidosPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Cuidados com o braço" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <Paragrafo>
                            Logo após a cirurgia, pode ocorrer algumas complicações do lado operado, como: dormência, dor, diminuição da amplitude do braço, diminuição da força muscular, alteração da sensibilidade e da postura. Porém, a maioria desses sintomas desaparecem com exercícios de fisioterapia.
                        </Paragrafo>

                        <Paragrafo>
                            Na primeira semana após a cirurgia
                        </Paragrafo>

                        <Paragrafo>
                            Mantenha o braço do lado da mama operada apoiado sobre um travesseiro para que ele fique um pouco mais alto que o ombro. A posição deve ser confortável.
                        </Paragrafo>

                        <Paragrafo>
                            • Faça suas atividades diárias normalmente com o braço do lado da cirurgia: se pentear, tomar banho, se vestir e comer.
                        </Paragrafo>

                        <Paragrafo>
                            • Com o braço elevado na posição acima do nível do coração, abra e feche a mão. 
                        </Paragrafo>

                        <Paragrafo>
                            • Dobre e endireite seu cotovelo.
                        </Paragrafo>

                        <Paragrafo>
                            • Pratique exercícios de respiração profunda (usando o diafragma) 
                        </Paragrafo>

                        <Paragrafo>
                            • Não durma sobre o braço operado ou se deite desse lado.
                        </Paragrafo>

                        <Paragrafo>
                            Após a primeira semana e com a liberação do fisioterapeuta você pode realizar alguns exercícios em casa:
                        </Paragrafo>

                        <Paragrafo>
                            * Exercícios sentada
                        </Paragrafo>

                        <ImageScreen src={exercicioSentada} />

                        <Paragrafo>
                            * Exercício para parede torácica
                        </Paragrafo>

                        <ImageScreen src={paredeTOracica} />

                        <Paragrafo>
                            * Exercício para o ombro
                        </Paragrafo>

                        <ImageScreen src={exercicioOmbro} />

                        <Paragrafo>
                            * Exercício com o cotovelo
                        </Paragrafo>

                        <ImageScreen src={exercicioCotovelo} />

                        <Paragrafo>
                            * Exercício com o bastão
                        </Paragrafo>

                        <ImageScreen src={exercicioBastao} />

                        <Paragrafo>
                            A remoção de gânglios linfáticos da axila durante o processo cirúrgico deixa o membro superior (braço) do lado afetado com menos defesa contra as infecções e inflamações o que pode facilitar o aparecimento de edema ou linfedema (inchaço) crônico.
                        </Paragrafo>

                        <Paragrafo>
                            Alguns cuidados com o braço do lado da cirurgia são necessários:
                        </Paragrafo>

                        <Paragrafo>
                            • Usar luvas para manusear produtos químicos, agulhas, facas etc.
                        </Paragrafo>

                        <Paragrafo>
                            • Usar protetor solar.
                        </Paragrafo>

                        <Paragrafo>
                            • Usar repelentes para evitar picadas de inseto.
                        </Paragrafo>

                        <Paragrafo>
                            • Usar loção depilatória ou cortar os pelos da axila com tesoura sem ponta.
                        </Paragrafo>
                        
                        <Paragrafo>
                            • Desodorantes tipo bastão, sem álcool, são os mais adequados.
                        </Paragrafo>
                        
                        <Paragrafo>
                            • Realizar pausas constantes nas atividades com movimento repetitivo.
                        </Paragrafo>
                        
                        <Paragrafo>
                            • Evitar ferimentos na mão e no braço do lado operado.
                        </Paragrafo>
                        
                        <Paragrafo>
                            • Evitar tirar cutícula e não roer as unhas.
                        </Paragrafo>
                        
                        <Paragrafo>
                            • Evitar injeções.
                        </Paragrafo>
                        
                        <Paragrafo>
                            • Evitar aferir pressão arterial.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default ExpansoresDeTecidosPage