import React from 'react'
import Titulo from '../../components/Titulo'
import Button from '../../components/button'
import FooterButton from '../../components/FooterButton'

const InformarCirurgiaPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Informar Cirurgia Mamária Proposta" />
                </div>
                <div className="row">
                    <div className="col content">
                        <Button
                            text="Cirurgia Conservadora"
                            to={"cirurgiaConservadora"}
                        />
                        <br />
                        <Button
                            text="Mastectomia"
                            to={"mastectomiaTotal"}
                        />
                        <br />
                        <Button
                            text="Reconstrução mamária"
                            to={"reconstrucao"}
                        />
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default InformarCirurgiaPage

// export default class InformarCirurgiaScreen extends Component {
//     static navigationOptions = {header: null}
//     render(){
//         return (
//             <View style={styles.container}>
//                 <ImageBackground
//                     source={assets.background}
//                     style={styles.background}
//                 >
//                     <Logo />

//                     <ScrollView>
//                         <Text
//                             style={styles.titlePage}
//                         >Informar Cirurgia Mamária Proposta</Text>
//                         <Button
//                             text="Cirurgia Conservadora"
//                             onPress={() => this.props.navigation.navigate('cirurgiaConservadora')}
//                         />

//                         <Button
//                             text="Mastectomia"
//                             onPress={() => this.props.navigation.navigate("mastectomiaTotal")}
//                         />

//                         <Button
//                             text="Reconstrução mamária"
//                             onPress={() => this.props.navigation.navigate("reconstrucao")}
//                         />
//                     </ScrollView>
//                     <TouchableHighlight
//                         onPress={() => this.props.navigation.pop()}
//                     >
//                         <View
//                             style={{
//                                 height: 50,
//                                 alignItems: 'center',
//                                 justifyContent: 'center',
//                                 backgroundColor: 'white'
//                             }}
//                         >
//                             <Text>Voltar</Text>
//                         </View>
//                     </TouchableHighlight>
                    
//                 </ImageBackground>
//             </View>
//         )
//     }
// }