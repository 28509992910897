import React from 'react'
import FooterButton from '../../components/FooterButton'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'

const IntroducaoPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className={"row"}>
                    <Titulo texto="Introdução" />
                </div>
                <div className={"content row"}>
                    <div className="col">
                        <Paragrafo>
                            O câncer de mama é a neoplasia maligna que mais acomete as mulheres do Brasil, com exceção dos casos de câncer de pele não melanoma. Ocorre devido a um crescimento anormal de células mamárias e com potencial para invadir outros tecidos e órgãos, o que é denominado metástases.
                        </Paragrafo>

                        <Paragrafo>
                            Geralmente o câncer de mama tem boas chances de cura, principalmente se diagnosticado precocemente.
                        </Paragrafo>
                        <Paragrafo>
                            O tratamento do câncer de mama deve ser individualizado e cada paciente será tratada de modo específico após discussão realizada por equipe multiprofissional.
                        </Paragrafo>
                        <Paragrafo>
                            As modalidades de tratamento do câncer de mama podem ser divididas em:
                        </Paragrafo>
                        <Paragrafo>
                            - Tratamento local (trata a mama e linfonodos axilares): cirurgia e radioterapia.
                        </Paragrafo>
                        <Paragrafo>
                           - Tratamento sistêmico (trata o organismo como um todo): quimioterapia, hormonioterapia e terapia alvo.
                        </Paragrafo>
                        <Paragrafo>
                            A mama acometida por câncer poderá ser submetida ao tratamento cirúrgico conservador que preserva a mama ou radical, onde toda a mama é retirada.
                        </Paragrafo>
                        <Paragrafo>
                            Quando necessário, técnicas de cirurgia plástica são utilizadas na mama afetada pelo câncer e na mama contralateral com o objetivo de restaurar a aparência natural.
                        </Paragrafo>
                        <Paragrafo>
                            A principal via de disseminação do câncer de mama é linfática, portanto, é muito importante a informação sobre a presença ou não de metástases axilares para definição do tratamento e avaliação do prognóstico. A avaliação pode ser feita através da pesquisa do linfonodo sentinela ou linfadenectomia axilar.
                        </Paragrafo>
                        <Paragrafo>
                            O tratamento radioterápico consiste no o uso de radiações ionizantes para destruir ou inibir o crescimento das células tumorais. Geralmente é realizada na sequência do tratamento cirúrgico conservador com o objetivo de diminuir o risco de recidiva tumoral. Também é realizado nos tumores localmente avançados, nos casos de metástase axilar e em outros casos específicos.
                        </Paragrafo>
                        <Paragrafo>
                            A quimioterapia consiste no uso de medicamentos com a função de eliminar células tumorais. Os medicamentos entram na circulação sanguínea e age sobre as células tumorais como também em células sadias o que pode acarretar efeitos colaterais.
                        </Paragrafo>
                        <Paragrafo>
                            A hormonioterapia consiste no uso de medicamentos com o objetivo de impedir o crescimento tumoral inibindo a ação de hormônios em células que possuem receptores sensíveis. O bloqueio hormonal deve ser mantido por 5 a 10 anos.
                        </Paragrafo >
                        <Paragrafo>
                            A terapia alvo consiste no uso de medicamentos que agem especificamente em determinados elementos da célula. Assim, essas substâncias localizam e bloqueiam as células tumorais, reduzindo assim, suas atividades sobre as células saudáveis e os efeitos colaterais.
                        </Paragrafo >
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}

export default IntroducaoPage