import React from 'react'
import Titulo from '../../components/Titulo'
import Paragrafo from '../../components/Paragrafo'
import SubTitulo from '../../components/SubTitulo'
import FooterButton from '../../components/FooterButton'
import ImageScreen from '../../components/ImageScreen'
import image from '../../img/imagesScreens/10.png'

const BiopsiaLinfonodoSentinelaPage = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Titulo texto="Biopsia de Linfonodo Sentinela" />
                    <ImageScreen src={image} />
                </div>
                <div className="row">
                    <div className="col content">
                        <SubTitulo>O que é?</SubTitulo>
                        <Paragrafo>
                            O câncer de mama normalmente se dissemina inicialmente para os linfonodos da axila.
                        </Paragrafo>

                        <Paragrafo>
                            Os linfonodos são órgãos do sistema linfático que atuam na defesa do nosso organismo contra infecções.
                        </Paragrafo>

                        <Paragrafo>
                            Linfonodo sentinela é o primeiro linfonodo (gânglio linfático) a receber a drenagem linfática da mama.
                        </Paragrafo>

                        <Paragrafo>
                            Este procedimento tem o objetivo de avaliar se o câncer de mama disseminou para os linfonodos axilares e se é necessário a realização de linfadectomia axilar ( retirada do maior número possível de linfonodos axilares).
                        </Paragrafo>

                        <SubTitulo>Quando utilizar?</SubTitulo>

                        <Paragrafo>
                            A biopsia do linfonodo sentinela está indicada nos carcinomas invasivos com axila clinicamente negativa para neoplasia e nos casos que os linfonodos se tornaram clinicamente e radiologicamente negativos após quimioterapia neoadjuvante (antes da cirurgia).
                        </Paragrafo>

                        <SubTitulo>Como é realizada?</SubTitulo>

                        <Paragrafo>
                            Durante a cirurgia será realizado a localização do linfonodo sentinela e a sua análise pelo patologista.
                        </Paragrafo>

                        <Paragrafo>
                            A localização do linfonodo sentinela é realizada através de duas técnicas:
                        </Paragrafo>

                        <Paragrafo>
                            • Medicina nuclear: consiste na realização de linfocintilografia mamária, no qual uma substância radioativa é injetada na véspera ou no dia da cirurgia. Essa substância é absorvida pelo linfonodo sentinela. Na cirurgia o linfonodo é identificado na axila através de uma sonda especial da radiação gama (probe).
                        </Paragrafo>

                        <Paragrafo>
                            • Azul patente: consiste na injeção de um corante azul na região do mamilo minutos antes do procedimento cirúrgico, o qual é captado pelo linfonodo sentinela. Na cirurgia o linfonodo corado de azul é identificado na axila.
                        </Paragrafo>

                        <SubTitulo>Particularidades</SubTitulo>

                        <Paragrafo>
                            A avaliação dos linfonodos da cadeia axilar no câncer de mama é muito importante, pois interfere diretamente na tomada de decisões terapêuticas, sendo importante fator prognóstico.
                        </Paragrafo>

                        <Paragrafo>
                            A técnica tem o objetivo reduzir o dano na drenagem linfática da axila e consequentemente o linfedema (inchaço no braço), dor, limitação de movimento, dormência e fraqueza do braço, sendo importante prevenir essas complicações com a realização de fisioterapia pré e pós-operatória.
                        </Paragrafo>
                    </div>
                </div>
            </div>
            <FooterButton 
                text="Voltar"
                to="/"
            />
        </>
    )
}
export default BiopsiaLinfonodoSentinelaPage